<template src="./all.html"></template>

<script lang="ts">
    import { axiosRequests } from '../../../services/axiosinstance';
    import { defineComponent } from 'vue';
    import { editUrl, retryGetPermissions } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { TableColum, TableRow } from '../../../models/Table'
    import { Clients } from '../../../models/Clients'
    import { SelectModel } from '../../../models/GUIModels';

    interface ComponentModel {
        columTemplates: TableColum[],
        allData: any[],
        tableData: TableRow[],
        filterDate: string | null,
        filterSex: SelectModel | null,
        filterAgreement: SelectModel | null,
        filterStatus: SelectModel | null,
        sex: SelectModel[],
        statuses: SelectModel[],
        agreements: SelectModel[],
        isShowFilters: boolean,
        isShowNewClientButton: boolean,
        isShowDownloadButton: boolean,
        isLoad: boolean,
        isCtrPressed: boolean
    }

    export default defineComponent({
        name: 'AllMembers',
        data(): ComponentModel {
            return {
                columTemplates: [],
                allData: [],
                tableData: [],
                filterDate: null,
                sex: [
                    { id: "false", text: 'clients.female', isTranslated: true, disabled: false, groupName: null },
                    { id: "true", text: 'clients.male', isTranslated: true, disabled: false, groupName: null },
                ],
                statuses: [
                    { id: "0", text: 'contract_status.new', isTranslated: true, disabled: false, groupName: null },
                    { id: "1", text: 'contract_status.active', isTranslated: true, disabled: false, groupName: null },
                    { id: "2", text: 'contract_status.frozen', isTranslated: true, disabled: false, groupName: null },
                    { id: "3", text: 'contract_status.indebt', isTranslated: true, disabled: false, groupName: null },
                    { id: "4", text: 'contract_status.blocked', isTranslated: true, disabled: false, groupName: null },
                    { id: "5", text: 'contract_status.finished', isTranslated: true, disabled: false, groupName: null },
                    { id: "6", text: 'contract_status.terminated', isTranslated: true, disabled: false, groupName: null },
                    { id: "10", text: 'contract_status.cancelled', isTranslated: true, disabled: false, groupName: null },
                ],
                filterSex: null,
                filterAgreement: null,
                filterStatus: null,
                agreements: [],
                isShowFilters: false,
                isShowNewClientButton: false,
                isShowDownloadButton: false,
                isLoad: false,
                isCtrPressed: false
            }
        },
        methods: {
            clearAllFilters: function () {
                this.filterDate = null;
                this.filterSex = null;
                this.filterAgreement = null;
                this.filterStatus = null;
                this.applyFilter();
            },
            applyFilter: function () {
                this.tableData = this.allData.filter(x => {
                    if (this.filterDate == null && this.filterSex == null && this.filterAgreement == null && this.filterStatus == null) {
                        return true;
                    } else if (this.filterDate != null && this.filterSex == null && this.filterAgreement == null && this.filterStatus == null) {
                        if (x.data[6].toDateString() == new Date(this.filterDate).toDateString()) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterSex != null && this.filterAgreement == null && this.filterStatus == null) {
                        if (x.data[3] == this.filterSex?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterSex == null && this.filterAgreement != null && this.filterStatus == null) {
                        if (x.data[4] == this.filterAgreement?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterSex != null && this.filterAgreement == null && this.filterStatus == null) {
                        if (x.data[6].toDateString() == new Date(this.filterDate).toDateString() && x.data[3] == this.filterSex?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterSex != null && this.filterAgreement != null && this.filterStatus == null) {
                        if (x.data[3] == this.filterSex?.id && x.data[4] == this.filterAgreement?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterSex == null && this.filterAgreement != null && this.filterStatus == null) {
                        if (x.data[6].toDateString() == new Date(this.filterDate).toDateString() && x.data[4] == this.filterAgreement?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterSex != null && this.filterAgreement != null && this.filterStatus == null) {
                        if (x.data[6].toDateString() == new Date(this.filterDate).toDateString() && x.data[3] == this.filterSex?.id && x.data[4] == this.filterAgreement?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterSex == null && this.filterAgreement == null && this.filterStatus != null) {
                        if (x.data[7] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterSex == null && this.filterAgreement == null && this.filterStatus != null) {
                        if (x.data[6].toDateString() == new Date(this.filterDate).toDateString() && x.data[7] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterSex != null && this.filterAgreement == null && this.filterStatus != null) {
                        if (x.data[3] == this.filterSex?.id && x.data[7] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterSex == null && this.filterAgreement != null && this.filterStatus != null) {
                        if (x.data[4] == this.filterAgreement?.id && x.data[7] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterSex != null && this.filterAgreement == null && this.filterStatus != null) {
                        if (x.data[6].toDateString() == new Date(this.filterDate).toDateString() && x.data[3] == this.filterSex?.id && x.data[7] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterSex != null && this.filterAgreement != null && this.filterStatus != null) {
                        if (x.data[3] == this.filterSex?.id && x.data[4] == this.filterAgreement?.id && x.data[7] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterSex == null && this.filterAgreement != null && this.filterStatus != null) {
                        if (x.data[6].toDateString() == new Date(this.filterDate).toDateString() && x.data[4] == this.filterAgreement?.id && x.data[7] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterSex != null && this.filterAgreement != null && this.filterStatus != null) {
                        if (x.data[6].toDateString() == new Date(this.filterDate).toDateString() && x.data[3] == this.filterSex?.id && x.data[4] == this.filterAgreement?.id && x.data[7] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }

                });
            },
            openClient: function (id: string) {
                if (!id) return;

                if (this.isCtrPressed) {
                    this.$store.commit('SET_LAST_VIEWED_CLIENT', { fi: "", id: id, buttonText: "", buttonPath: null, isNeedTranslatedButtonText: false });

                    const index = this.allData.findIndex(x => x.id.toString() == id);
                    if (index != -1) this.$store.commit('UPDATE_LAST_VIEWED_CLIENT', { fi: this.allData[index].data[0], id: id });

                    window.open('/client/' + id, '_blank');
                } else {
                    this.$store.commit('SET_LAST_VIEWED_CLIENT', { fi: "", id: id, buttonText: 'back_button.clients_all', buttonPath: `/clients/all`, isNeedTranslatedButtonText: true });

                    this.$router.push('/client/' + id);
                }
            },
            checkCtrPressed: function (e: any) {
                if (e.keyCode == 17) this.isCtrPressed = true;
            },
            checkCtrDroped: function (e: any) {
                if (e.keyCode == 17) this.isCtrPressed = false;
            },
            downloadFile: function () {
                axiosRequests.getFile(editUrl(Routes.downloadAllClients))
                    .then(
                        (data: any) => {

                            let fileName = "";

                            if (data.headers["content-disposition"]) {
                                let contentDisposittion = data.headers["content-disposition"].split(";");
                                if (contentDisposittion.length == 2) fileName = decodeURIComponent(contentDisposittion[1].trim().replace('filename=', ''));
                            }

                            if (fileName == "") {
                                this.$store.commit('SET_ADD_ERROR', 'error.error_downloading_file');
                                return;
                            }

                            let fileURL = window.URL.createObjectURL(data.data);
                            let download: any = this.$refs.download;
                            download.href = fileURL;
                            download.setAttribute('download', fileName);
                            download.click()
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
        },
        async beforeMount() {
            window.addEventListener("keyup", this.checkCtrDroped);
            window.addEventListener("keydown", this.checkCtrPressed);

            let clientsPermission = this.$store.getters.getClientsPermission;

            if (clientsPermission.length == 0) clientsPermission = await retryGetPermissions("client");

            if (!clientsPermission?.includes('clients')) this.$router.push('/forbidden');

            this.isShowNewClientButton = clientsPermission.includes('clients/create');
            this.isShowDownloadButton = clientsPermission.includes('clients/download');

            this.isLoad = true;

            await axiosRequests.get(editUrl(Routes.getClients))
                .then(
                    (data: Clients[]) => {
                        this.columTemplates = [
                            { title: 'clients.all.fi', type: 'text', isSortable: true, template: '%data%' },
                            { title: 'clients.all.email', type: 'text', isSortable: true, template: '<div class="email_and_phone">%data%</div>' },
                            { title: 'clients.all.phone', type: 'phone', isSortable: true, template: '<div class="email_and_phone">%data%</div>' },
                            { title: 'clients.all.sex', type: 'sex', isSortable: true, template: '<div class="sex">%data%</div>' },
                            { title: 'clients.all.agreement', type: 'text', isSortable: true, template: '<div class="agreement">%data%</div>' },
                            { title: 'clients.all.contract', type: 'text', isSortable: true, template: '<div class="contract">%data%</div>' },
                            { title: 'clients.all.start_date', type: 'date', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                            { title: 'clients.all.status', type: 'contract_status', isSortable: false, template: '<div class="flex status_text_%color%"><div class="dot dot_contract_%color%"></div>%data%</div>' }
                        ];
                       
                        let allAgreements: any = [...new Set(data.map(item => item.agreement))];

                        this.agreements = allAgreements.map((x: string) => {
                            return {
                                id: x,
                                text: x,
                                isTranslated: false,
                                disabled: false
                            }
                        });

                        this.allData = data.map(x => { return { id: x.id, data: [`${x.lastName} ${x.firstName}`, x.email, x.phone, x.sex.toString(), x.agreement, x.contract, x.startDate, x.status] } });

                        this.tableData = this.allData;

                        this.isLoad = false;
                    }
                )
                .catch(
                    async (err: any) => {
                        this.isLoad = false;

                        if (err.status == 401) {
                            return;
                        } else if (err.status == 500) {
                            this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                        } else if (err.status == 400 && err.data) {
                            this.$store.commit('SET_ADD_ERROR', err.data);
                        } else {
                            this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                        }
                    }
                );
        },
        beforeUnmount: function () {
            window.removeEventListener("keyup", this.checkCtrDroped);
            window.removeEventListener("keydown", this.checkCtrPressed);
        }
    });
</script>

<style scoped>
    .filter_wrapper{
        display: flex;
    }

    .filter_right_wrapper {
        display: flex;
        padding-left: 8px
    }

    .filter_left_wrapper {
        width: 100%;
        display: flex;
        padding-right: 8px
    }

    .small_filter_input {
        width: 266px !important;
    }

    .status_filter_input {
        min-width: 266px !important;
        width: 266px !important;
        margin-left: 16px;
    }

    .table_wrapper {
        overflow: auto;
        padding-right: 10px;
    }

    .clear_all_filters {
        color: #21A7D1;
        cursor: pointer;
        margin: 8px 0;
        width: fit-content;
    }

    .total {
        color: #65656F;
        margin: 0;
        font-weight: 600;
    }

    .filter_button{
        display: none;
    }

    .total_wrapper{
        display: flex;
        justify-content: space-between;
    }

    .load {
        color: #df2793;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 12px;
        padding-right: 10px;
    }

    .new_client {
        color: #00a3ba;
        cursor: pointer;
        text-decoration: underline;
    }

    .new_client_mobile_button{
        display: none;
    }

    @media(max-width: 1200px) {
        .small_filter_input {
            width: 50% !important;
        }

        .status_filter_input {
            min-width: 49% !important;
        }


        .filter_wrapper {
            display: block;
        }

        .filter_right_wrapper {
            margin-top: 12px;
            padding-left: 0px
        }

        .filter_left_wrapper {
            padding-right: 0;
        }

        .load{
            display: none;
        }
    }

    @media(max-width: 500px) {
        .filter_right_wrapper {
           display:block;
        }

        .filter_left_wrapper {
            display: block;
        }

        .small_filter_input {
            width: 100% !important;
        }

        .status_filter_input {
            min-width: 100% !important;
            margin-left: 0px;
            margin-top: 12px;
        }

        .date_input{
            margin-top: 16px;
        }

        .show_filtes_mobile {
            display: block;
        }

        .hide_filtes_mobile{
            display: none;
        }

        .filter_button {
            display: flex;
            width: 100%;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            background-color: #f2f2f2;
            font-size: 16px;
            line-height: 24px;
        }

        .new_client{
            display: none
        }


        .table_wrapper {
            padding-right: 5px;
        }

        .new_client_mobile_button {
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            background-color: #00a3ba;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
            margin-top: 6px;
            width: 100%;
            color: white;
        }
    }

    @media(max-width: 500px){
        :deep(.dot) {
           display: none;
        }
        
        :deep(.status_text_new) {
            background-color: white;
            padding: 4px 10px;
            border-radius: 12px;
            color: black;
            border: 0.1px solid;
        }

        :deep(.status_text_active) {
            background-color: #def8e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: green;
        }

        :deep(.status_text_frozen) {
            background-color: #e1e1ff;
            padding: 4px 10px;
            border-radius: 12px;
            color: blue;
        }

        :deep(.status_text_indebt) {
            background-color: #fff1d7;
            padding: 4px 10px;
            border-radius: 12px;
            color: orange;
        }

        :deep(.status_text_blocked) {
            background-color: #b9b94b;
            padding: 4px 10px;
            border-radius: 12px;
            color: yellow;
        }

        :deep(.status_text_finished) {
            background-color: #b4b4b4;
            padding: 4px 10px;
            border-radius: 12px;
            color: black;
        }

        :deep(.status_text_terminated) {
            background-color: #efefef;
            padding: 4px 10px;
            border-radius: 12px;
            color: gray;
        }

        :deep(.status_text_cancelled) {
            background-color: #ffe5e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: red;
        }

        :deep(.email_and_phone) {
            color: #0d99ff;
            margin-top: 5px;
        }

        :deep(.sex) {
            display: none;
        }

        :deep(.agreement) {
            margin-top: 5px;
        } 
        
        :deep(.contract) {
            color: #a19d9d;
            font-size: 12px;
            margin-top: 6px;
        }

        :deep(.date) {
            background-color: #f5f5f5;
            padding: 1px 7px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex;
        }
    }
</style>
