<button class="new_client_mobile_button" v-if="!isShowFilters && isShowNewClientButton" @click="$router.push('/client/create')">{{$t('clients.add_client')}}</button>
<div :class="isShowFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile'">
    <div class="filter_wrapper pt-2">
        <div class="filter_left_wrapper">
            <v-select v-model="filterAgreement" :label="'clients.former.agreement'" class="w-100 " :items="agreements" @update:modelValue="applyFilter" clearable></v-select>
            <v-select v-model="filterManager" :label="'clients.former.manager'" class="w-100 manager_filter" :items="managers" @update:modelValue="applyFilter" clearable></v-select>
        </div>
       
        <div class="filter_right_wrapper">
            <v-input class="small_filter_input date_input mr-4" v-model="filterEndDate" :label="$t('clients.former.end_date')" inputType="date" height="22" @update:modelValue="applyFilter" clearable></v-input>
            <v-input class="small_filter_input date_input" v-model="filterContactDate" :label="$t('clients.former.contact_date')" inputType="date" height="22" @update:modelValue="applyFilter" clearable></v-input>
        </div>
       
    </div>
    <div><p @click="clearAllFilters" class="clear_all_filters pl-2">{{$t('clients.clear_all_filters')}}</p></div>
</div>

<button class="filter_button" @click="isShowFilters = !isShowFilters">{{ !isShowFilters ? $t('clients.filter') : $t('clients.hide')}} <img v-show="!isShowFilters" src="/assets/img/filter.svg"/></button>

<div class="mt-3 total_wrapper">
    <p class="total pl-2" v-show="isLoad"></p>
    <p class="total pl-2" v-show="!isLoad">{{$t('clients.total')}}: {{tableData.length}}</p>
    <div>
        <a v-if="isShowNewClientButton" class="new_client" @click="$router.push('/client/create')">{{$t('clients.add_client')}}</a>
        <a v-if="isShowDownloadButton" class="load" @click="downloadFile">{{$t('clients.load')}}</a>
        <a v-show="false" ref="download"></a>
    </div>

</div>

<div class="mt-2 table_wrapper">
    <v-datatable class="pointer" :columTemplates="columTemplates" :data="tableData" :isLoading="isLoad" @clickOnRow="openClient"></v-datatable>
</div>

