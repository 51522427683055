<template src="./clients.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl } from '../../services/helper';
    import { Routes } from '../../const';
    import ActiveMembers from './Active/Active.vue'
    import AllMembers from './All/All.vue'
    import MembersInClub from './InClub/InClub.vue'
    import NewMembers from './NewContracts/NewContracts.vue'
    import InDebtMembers from './InDebt/InDebt.vue'
    import EndSoonMembers from './EndSoon/EndSoon.vue'
    import FormerMembers  from './Former/Former.vue'
    import CorporateMembers from './CorporateContracts/CorporateContracts.vue'
    import { Menu } from '../../models/Menu'

    interface ComponentModel {
        isShowActiveChild: boolean;
        isShowAllChild: boolean;
        isShowInClubChild: boolean;
        isShowNewChild: boolean;
        isShowInDebtChild: boolean;
        isShowEndSoonChild: boolean;
        isShowFormerChild: boolean;
        isShowCorporateChild: boolean;
        tabs: Menu[],
        activeTab: Menu,
    }

    export default defineComponent({
        name: 'ClubClients',
        data(): ComponentModel {
            return {
                isShowActiveChild: false,
                isShowAllChild: false,
                isShowInClubChild: false,
                isShowNewChild: false,
                isShowInDebtChild: false,
                isShowEndSoonChild: false,
                isShowFormerChild: false,
                isShowCorporateChild: false,
                tabs: [],
                activeTab: { icon: '', subItems: [], text: '', url: '' },
            }
        },
        methods: {
            getTabs: async function () {
                await axiosRequests.get(editUrl(Routes.getMembersTabs))
                    .then(
                        (data: Menu[]) => {
                            this.tabs = data;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            clickOnTab: function () {
                this.$router.push(this.activeTab.url);

                this.isShowActiveChild = false;
                this.isShowAllChild = false;
                this.isShowInClubChild = false;
                this.isShowNewChild = false;
                this.isShowInDebtChild = false;
                this.isShowEndSoonChild = false;
                this.isShowFormerChild = false;
                this.isShowCorporateChild = false;

                if (this.activeTab.url === '/clients/active') {
                    this.isShowActiveChild = true;
                } else if (this.activeTab.url === '/clients/all') {
                    this.isShowAllChild = true;
                } else if (this.activeTab.url === '/clients/in-club') {
                    this.isShowInClubChild = true;
                } else if (this.activeTab.url === '/clients/new-contracts') {
                    this.isShowNewChild = true;
                } else if (this.activeTab.url === '/clients/in-debt') {
                    this.isShowInDebtChild = true;
                } else if (this.activeTab.url === '/clients/end-soon') {
                    this.isShowEndSoonChild = true;
                } else if (this.activeTab.url === '/clients/former') {
                    this.isShowFormerChild = true;
                } else if (this.activeTab.url === '/clients/corporate-contracts') {
                    this.isShowCorporateChild = true;
                }
            },
            deleteLastViewedClient: function (index: number) {
                this.$store.commit('SET_REMOVE_LAST_VIEWED_CLIENT', index);
            },
            clickOnLastViewedClient: function (id: number) {
                this.$store.commit('UNSHIFT_LAST_VIEWED_CLIENT', id); 
                this.$router.push('/client/' + id);
            }
        },
        components: { active: ActiveMembers, all: AllMembers, inClub: MembersInClub, inDebt: InDebtMembers, endSoon: EndSoonMembers, former: FormerMembers, corporate: CorporateMembers, new: NewMembers },
        watch: {
            '$route.params.tab': {
                handler: async function (tab) {
                    let selectTab = "";

                    if(this.tabs.length == 0) await this.getTabs();
                    
                    if(this.tabs.length == 0){
                        this.$router.push('/forbidden');
                        return;
                    }
                    
                    this.isShowActiveChild = false;
                    this.isShowAllChild = false;
                    this.isShowInClubChild = false;
                    this.isShowNewChild = false;
                    this.isShowInDebtChild = false;
                    this.isShowEndSoonChild = false;
                    this.isShowFormerChild = false;
                    this.isShowCorporateChild = false;

                    if (tab){
                        let index = this.tabs.findIndex(x => x.url == this.$route.path.toString());
                        if (index != -1){
                            this.activeTab = this.tabs[index];
                            selectTab = tab;
                        }else{
                            this.$router.push('/forbidden');
                            return;
                        }                       
                    } else {
                        this.activeTab = this.tabs[0];
                        history.pushState(history.state, "", this.activeTab.url);

                        let url = this.activeTab.url.split("/")
                        selectTab = url[url.length - 1];
                    }

                    if (selectTab === 'active') {
                        this.isShowActiveChild = true;
                    } else if (selectTab === 'all') {
                        this.isShowAllChild = true;
                    } else if (selectTab === 'in-club') {
                        this.isShowInClubChild = true;
                    } else if (selectTab === 'new-contracts') {
                        this.isShowNewChild = true;
                    }else if (selectTab === 'in-debt') {
                        this.isShowInDebtChild = true;
                    }else if (selectTab === 'end-soon') {
                        this.isShowEndSoonChild = true;
                    }else if (selectTab === 'former') {
                        this.isShowFormerChild = true;
                    }else if (selectTab === 'corporate-contracts') {
                        this.isShowCorporateChild = true;
                    }
                },
                deep: true,
                immediate: true
            }
        },
        computed: {
            lastViewedClients: function () {
                return this.$store.getters.getLastViewedClients;
            }
        },
    });
</script>

<style scoped>
    .members_wrapper {
        overflow: hidden;
        max-height: calc(100vh - 189px);
        min-height: 200px;
        display: flex;
        flex-flow: column;
    }

    .members_wrapper_with_last_viewed_clients {
        overflow: hidden;
        max-height: calc(100vh - 220px);
        min-height: 200px;
        display: flex;
        flex-flow: column;
    }


    @media(max-width: 1565px) {
        .members_wrapper_with_last_viewed_clients {
            width: calc(100vw - 80px);
        }
    }
    @media(max-width: 1200px) {
        .last_viewed_client_hover_end {
            right: 0px;
            left: unset;
        }
    }
   
    @media(max-width: 768px) {
        .members_wrapper {
            max-height: calc(100vh - 165px);
        }

        .members_wrapper_with_last_viewed_clients {
            max-height: calc(100vh - 210px);
        }
    }

    @media(max-width: 650px) {
        .members_wrapper_with_last_viewed_clients {
            max-height: calc(100vh - 165px);
        }
    }

</style>