<template src="./mailings.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl, retryGetPermissions } from '../../services/helper';
    import { Routes } from '../../const';
    import { TableColum, TableRow } from '../../models/Table'
    import { SelectModel } from '../../models/GUIModels';
    import { Mailing } from '../../models/Mailing';

    interface ComponentModel {
        columTemplates: TableColum[],
        allData: any[],
        tableData: TableRow[],
        filterMailingType: SelectModel | null,
        filterMailingGroups: SelectModel | null,
        filterStatus: SelectModel | null,
        filterDate: string | null,
        mailingTypes: SelectModel[],
        mailingGroups: SelectModel[],
        stauses: SelectModel[],
        isShowFilters: boolean,
        isShowNewMailingButton: boolean,
        isLoad: boolean,
    }

    export default defineComponent({
        name: 'ViewMailings',
        data(): ComponentModel {
            return {
                columTemplates: [
                    { title: 'mailings.subject', type: 'text', isSortable: true, template: '<div class="title">%data%</div>' },
                    { title: 'mailings.mailing_type', type: 'text', isSortable: true, template: '<div class="mailing_type">%data%</div>' },
                    { title: 'mailings.send_date', type: 'datetime', isSortable: true, template: '<div class="date"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'mailings.mailing_group', type: 'text', isSortable: true, template: '<div class="mailing_group">%data%</div>' },
                    { title: 'mailings.periodic', type: 'bool', isSortable: true, template: '<div class="mobile_hide">%data%</div>' },
                    { title: 'mailings.messages_sent', type: 'text', isSortable: true, template: '<div class="messages_sent"><span class="hide_in_desktop">%title%:</span>%data%</div>' },
                    { title: 'mailings.status', type: 'mailing_status', isSortable: true, template: '<div class="flex status_text_%color%"><div class="dot dot_mailing_status_%color%"></div>%data%</div>' },
                    
                ],
                allData: [],
                tableData: [],
                filterMailingType:  null,
                filterMailingGroups: null,
                filterStatus: null,
                filterDate: null,
                mailingTypes: [
                    { id: "notification_type.mail", text: "notification_type.mail", isTranslated: true, disabled: false, groupName: null },
                    { id: "notification_type.sms", text: "notification_type.sms", isTranslated: true, disabled: false, groupName: null },
                    { id: "notification_type.notification", text:"notification_type.notification", isTranslated: true, disabled: false, groupName: null }
                ],
                mailingGroups: [],
                stauses: [
                    { id: "0", text: 'mailing_status.new', isTranslated: true, disabled: false, groupName: null },
                    { id: "1", text: 'mailing_status.awaiting_dispatch', isTranslated: true, disabled: false, groupName: null },
                    { id: "2", text: 'mailing_status.sending', isTranslated: true, disabled: false, groupName: null },
                    { id: "3", text: 'mailing_status.sent', isTranslated: true, disabled: false, groupName: null },
                    { id: "4", text: 'mailing_status.not_ready', isTranslated: true, disabled: false, groupName: null },
                    { id: "5", text: 'mailing_status.stopped', isTranslated: true, disabled: false, groupName: null },
                ],
                isShowFilters: false,
                isShowNewMailingButton: false,
                isLoad: true,

            }
        },
        methods: {
            getMailings: async function () {
                this.isLoad = true;

                await axiosRequests.get(editUrl(Routes.getMailings))
                    .then(
                        (data: Mailing[]) => {
                            this.allData = data.map(x => { return { id: x.mailingId.toString(), data: [x.subject, this.$t(x.notificationTypeName), new Date(x.sendingDate), x.mailingGroupName, x.isPeriodic.toString(), x.sentMessagesNumber.toString(), x.status] } });

                            this.tableData = this.allData;

                            let allAGroups: any = [...new Set(data.map(item => item.mailingGroupName))];

                            this.mailingGroups = allAGroups.map((x: string) => {
                                return {
                                    id: x,
                                    text: x,
                                    isTranslated: false,
                                    disabled: false
                                }
                            });

                            this.isLoad = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            clearAllFilters: function () {
                this.filterMailingType = null;
                this.filterMailingGroups = null;
                this.filterStatus = null;
                this.filterDate = null;
                this.applyFilter();
            },
            applyFilter: function () {
                this.tableData = this.allData.filter(x => {
                    if (this.filterDate == null && this.filterMailingType == null && this.filterMailingGroups == null && this.filterStatus == null) {
                        return true;
                    } else if (this.filterDate != null && this.filterMailingType == null && this.filterMailingGroups == null && this.filterStatus == null) {
                        if (x.data[2].toDateString() == new Date(this.filterDate).toDateString()) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterMailingType != null && this.filterMailingGroups == null && this.filterStatus == null) {
                        if (x.data[1] == this.$t(this.filterMailingType.id)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterMailingType == null && this.filterMailingGroups != null && this.filterStatus == null) {
                        if (x.data[3] == this.filterMailingGroups?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterMailingType != null && this.filterMailingGroups == null && this.filterStatus == null) {
                        if (x.data[2].toDateString() == new Date(this.filterDate).toDateString() && x.data[1] == this.$t(this.filterMailingType.id)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterMailingType != null && this.filterMailingGroups != null && this.filterStatus == null) {
                        if (x.data[1] == this.$t(this.filterMailingType.id) && x.data[3] == this.filterMailingGroups?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterMailingType == null && this.filterMailingGroups != null && this.filterStatus == null) {
                        if (x.data[2].toDateString() == new Date(this.filterDate).toDateString() && x.data[3] == this.filterMailingGroups?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterMailingType != null && this.filterMailingGroups != null && this.filterStatus == null) {
                        if (x.data[2].toDateString() == new Date(this.filterDate).toDateString() && x.data[1] == this.$t(this.filterMailingType.id) && x.data[3] == this.filterMailingGroups?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterMailingType == null && this.filterMailingGroups == null && this.filterStatus != null) {
                        if (x.data[6] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterMailingType == null && this.filterMailingGroups == null && this.filterStatus != null) {
                        if (x.data[2].toDateString() == new Date(this.filterDate).toDateString() && x.data[6] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterMailingType != null && this.filterMailingGroups == null && this.filterStatus != null) {
                        if (x.data[1] == this.$t(this.filterMailingType.id) && x.data[6] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterMailingType == null && this.filterMailingGroups != null && this.filterStatus != null) {
                        if (x.data[3] == this.filterMailingGroups?.id && x.data[6] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterMailingType != null && this.filterMailingGroups == null && this.filterStatus != null) {
                        if (x.data[2].toDateString() == new Date(this.filterDate).toDateString() && x.data[1] == this.$t(this.filterMailingType.id) && x.data[6] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate == null && this.filterMailingType != null && this.filterMailingGroups != null && this.filterStatus != null) {
                        if (x.data[1] == this.$t(this.filterMailingType.id) && x.data[3] == this.filterMailingGroups?.id && x.data[6] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterMailingType == null && this.filterMailingGroups != null && this.filterStatus != null) {
                        if (x.data[2].toDateString() == new Date(this.filterDate).toDateString() && x.data[3] == this.filterMailingGroups?.id && x.data[6] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterDate != null && this.filterMailingType != null && this.filterMailingGroups != null && this.filterStatus != null) {
                        if (x.data[2].toDateString() == new Date(this.filterDate).toDateString() && x.data[1] == this.$t(this.filterMailingType.id) && x.data[3] == this.filterMailingGroups?.id && x.data[6] == this.filterStatus?.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }

                });
            },
            openMailing: function (id: string) {
                if (!id) return;

                this.$router.push('/mailing/' + id);
            },
        },
        async beforeMount() {
            let mailingsPermission = this.$store.getters.getMailingsPermission;

            if (mailingsPermission.length == 0) mailingsPermission = await retryGetPermissions("mailing");

            if (!mailingsPermission?.includes('mailings')) this.$router.push('/forbidden');

            this.isShowNewMailingButton = mailingsPermission?.includes('mailings/create');
            await this.getMailings();
        },
    });
</script>

<style scoped>
    .filter_wrapper {
        display: flex;
    }

    .table_wrapper {
        overflow: auto;
        padding-right: 10px;
        max-height: calc(100vh - 315px);
        min-height: 85px;
    }

    .table_wrapper_with_open_filter {
        overflow: auto;
        padding-right: 10px;
        max-height: calc(100vh - 315px);
        min-height: 85px;
    }

    .small_filter_input {
        min-width: 266px !important;
        width: 266px !important;
    }

    .clear_all_filters {
        color: #21A7D1;
        cursor: pointer;
        margin: 8px 0;
        width: fit-content;
    }

    .filter_right_wrapper {
        display: flex;
        padding-left: 8px
    }

    .filter_left_wrapper {
        width: 100%;
        display: flex;
        padding-right: 8px
    }

    .total {
        color: #65656F;
        margin: 0;
        font-weight: 600;
    }

    .filter_button {
        display: none;
    }

    .total_wrapper {
        display: flex;
        justify-content: space-between;
    }

    .new_training_class_button {
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
        margin-top: 6px;
        width: 266px;
        color: white;
    }

    .status_filter_input {
        min-width: 266px !important;
        width: 266px !important;
        margin-left: 16px;
    }

    @media(max-width: 1200px) {
        .small_filter_input {
            min-width: calc(50% - 8px) !important
        }

        .filter_wrapper {
            display: block;
        }

        .filter_right_wrapper {
            margin-top: 12px;
            padding-left: 0px
        }

        .status_filter_input {
            min-width: calc(50% - 8px) !important
        }

        .filter_left_wrapper {
            padding-right: 0;
        }
    }

    @media(max-width: 500px) {
        .filter_right_wrapper {
            display: block;
        }

        .filter_left_wrapper {
            display: block;
        }

        .filter_wrapper {
            display: block;
        }

        .status_filter_input {
            min-width: 100% !important;
            margin-left: 0px;
            margin-top: 12px;
        }

        .date_input {
            margin-top: 16px;
        }

        .show_filtes_mobile {
            display: block;
        }

        .hide_filtes_mobile {
            display: none;
        }

        .filter_button {
            display: flex;
            width: 100%;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            background-color: #f2f2f2;
            font-size: 16px;
            line-height: 24px;
        }

        .small_filter_input {
            min-width: 100% !important;
        }

        .table_wrapper_with_open_filter {
            padding-right: 5px;
            max-height: calc(100vh - 437px);
        }

        .table_wrapper {
            padding-right: 5px;
            max-height: calc(100vh - 276px);
        }

        .new_training_class_button{
            width: 100%;
        }

        .date_input {
            margin-top: 16px;
            margin-left: 0px !important;
        }

    }

    @media(max-width: 500px) {
        :deep(.dot) {
            display: none;
        }

        :deep(.status_text_new) {
            background-color: white;
            padding: 4px 10px;
            border-radius: 12px;
            color: black;
            border: 0.1px solid;
        }

        :deep(.status_text_awaiting_dispatch) {
            background-color: #fff1d7;
            padding: 4px 10px;
            border-radius: 12px;
            color: orange;
        }

        :deep(.status_text_sending) {
            background-color: #ffb6e1;
            padding: 4px 10px;
            border-radius: 12px;
            color: #AB0064;
        }

        :deep(.status_text_sent) {
            background-color: #def8e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: green;
        }

        :deep(.status_text_not_ready) {
            background-color: #ffe5e5;
            padding: 4px 10px;
            border-radius: 12px;
            color: red;
        }

        :deep(.status_text_stopped) {
            background-color: #efefef;
            padding: 4px 10px;
            border-radius: 12px;
            color: gray;
        }

        :deep(.mobile_hide) {
            display: none;
        }

        :deep(.title) {
            font-weight: 600;
            margin-bottom: 8px;
        }

        :deep(.mailing_type) {
            background-color: #f5f5f5;
            padding: 1px 7px;
            border-radius: 12px;
            color: black;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex;
        }

        :deep(.messages_sent) {
            display: flex;
            color: #a19d9d;
            margin-bottom: 10px;
        }

        :deep(.mailing_group) {
            margin-bottom: 10px
        }

        :deep(.date) {
            background-color: #f5f5f5;
            padding: 1px 7px;
            border-radius: 12px;
            color: black;
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 11px;
            letter-spacing: 1px;
            display: flex;
        }
    }
</style>