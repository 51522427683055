<template src="./users.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl, retryGetPermissions } from '../../services/helper';
    import { Routes } from '../../const';
    import { TableColum, TableRow } from '../../models/Table'
    import { User } from '../../models/User';
    import { SelectModel } from '../../models/GUIModels';

    interface ComponentModel {
        allData: any[],
        isShowFilters:boolean,
        filterNetwork: SelectModel | null,
        filterClub: SelectModel | null,
        networks: SelectModel[],
        clubs: SelectModel[],
        columTemplates: TableColum[],
        tableData: TableRow[],
        isLoad: boolean,
        isAdmin: boolean
    }

    export default defineComponent({
        name: 'UsersList',
        data(): ComponentModel {
            return {
                allData: [],
                columTemplates: [
                    { title: 'user.user', type: 'text', isSortable: true, template: '<div class="user">%data%</div>' },
                    { title: 'user.fi', type: 'text', isSortable: true, template: '<div class="fi">%data%</div>' },
                    { title: 'user.role', type: 'text', isSortable: true, template: '<div class="role">%data%</div>' },
                    { title: 'user.network', type: 'text', isSortable: true, template: '<div class="network">%data%</div>' },
                    { title: 'user.club', type: 'text', isSortable: true, template: '<div class="role">%data%</div>' },
                ],
                tableData: [],
                isLoad: true,
                isShowFilters: false,
                filterNetwork: null,
                filterClub: null,
                networks: [],
                clubs: [],
                isAdmin: false
            }
        },
        methods: {
            getUsers: async function () {
                this.isLoad = true;

                await axiosRequests.get(editUrl(Routes.getUsers))
                    .then(
                        (data: User[]) => {
                            this.allData = data.map(x => { return { id: x.id.toString(), data: [x.login, `${x.lastName} ${x.firstName}`, this.$t(x.mainRoleName), x.networkName, x.clubName] } });

                            this.tableData = this.allData;

                            let networks: any = [...new Set(data.map(item => item.networkName))];

                            this.networks = networks.map(x => {
                                return {
                                    id: x,
                                    text: x,
                                    isTranslated: false,
                                    disabled: false
                                }
                            }).sort((a, b) => {
                                const aName = a.text ?? "";
                                const bName = b.text ?? "";
                                if (aName <= bName) return -1;
                                if (aName > bName) return 1;
                            });

                            let clubs: any = [...new Set(data.map(item => item.clubName))];

                            this.clubs = clubs.map(x => {
                                return {
                                    id: x,
                                    text: x,
                                    isTranslated: false,
                                    disabled: false
                                }
                            }).sort((a, b) => {
                                const aName = a.text ?? "";
                                const bName = b.text ?? "";
                                if (aName <= bName) return -1;
                                if (aName > bName) return 1;
                            });

                            this.isLoad = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoad = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            openUser: function (id: string) {
                if (!id) return;

                this.$router.push('/user/' + id);
            },
            clearAllFilters: function () {
                this.filterNetwork = null;
                this.filterClub = null;
                this.applyFilter();
            },
            applyFilter: function () {
                this.tableData = this.allData.filter(x => {
                    if (this.filterNetwork == null && this.filterClub == null) {
                        return true;
                    } else if (this.filterNetwork != null && this.filterClub == null) {
                        if (x.data[3] == this.filterNetwork.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterNetwork == null && this.filterClub != null) {
                        if (x.data[4] == this.filterClub.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (this.filterNetwork != null && this.filterClub != null) {
                        if (x.data[3] == this.filterNetwork.id && x.data[4] == this.filterClub.id) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }

                });
            },
        },
        async beforeMount() {
            let userPermission = this.$store.getters.getUserPermission;

            if (userPermission.length == 0) userPermission = await retryGetPermissions("user");

            if (!userPermission?.includes('users')) this.$router.push('/forbidden');

            await this.getUsers();

            let currentUser = this.$store.getters.getCurrentUser;

            this.isAdmin = currentUser.mainRoleName == "role.administrator";
        },
    });
</script>

<style scoped>
    .table_wrapper {
        overflow: auto;
        padding-right: 10px;
        max-height: calc(100vh - 265px);
        min-height: 85px;
    }

    .total {
        color: #65656F;
        margin: 0;
        font-weight: 600;
    }

    .total_wrapper {
        display: flex;
        justify-content: space-between;
    }

    .filter_wrapper {
        display: flex;
    }

    .small_filter_input {
        min-width: 266px !important;
        width: fit-content !important;
    }

    .clear_all_filters {
        color: #21A7D1;
        cursor: pointer;
        margin: 8px 0;
        width: fit-content;
    }

    .filter_button {
        display: none;
    }


    @media(max-width: 1200px) {
        .small_filter_input {
            min-width: 49% !important;
        }
    }

    @media(max-width: 500px) {
        .table_wrapper {
            padding-right: 5px;
            max-height: calc(100vh - 225px);
        }

        .filter_wrapper {
            display: block;
        }

        .date_input {
            margin-top: 16px;
        }

        .show_filtes_mobile {
            display: block;
        }

        .hide_filtes_mobile {
            display: none;
        }

        .filter_button {
            display: flex;
            width: 100%;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            background-color: #f2f2f2;
            font-size: 16px;
            line-height: 24px;
        }

        .small_filter_input {
            min-width: 100% !important;
        }

        .table_wrapper_with_open_filter_with_network {
            padding-right: 5px;
            max-height: calc(100vh - 350px);
            overflow: auto;
            min-height: 85px;
        }

        .table_wrapper_with_open_filter {
            padding-right: 5px;
            max-height: calc(100vh - 306px);
            overflow: auto;
            min-height: 85px;
        }
    }

    @media(max-width: 500px) {
        :deep(.user) {
            color: #0d99ff;
            margin-bottom: 6px;
        }


        :deep(.role) {
            color: #a19d9d;
            font-size: 12px;
            margin-top: 6px;
        }
    }
</style>