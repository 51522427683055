<div class="content_wrapper">
    <div class="search_wrapper">
        <div class="total_wrapper">
            <p class="total pl-2" v-show="!isLoad">{{$t('search.find')}}: {{allData.length}}</p>
        </div>

        <div class="mt-2 table_wrapper">
            <v-datatable class="pointer" :columTemplates="columTemplates" :data="allData" :isLoading="isLoad" @clickOnRow="openClient"></v-datatable>
        </div>
    </div>
</div>




