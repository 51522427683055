import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fcd503a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content_wrapper" }
const _hoisted_2 = { class: "filter_wrapper" }
const _hoisted_3 = { src: "/assets/img/filter.svg" }
const _hoisted_4 = { class: "mt-3 total_wrapper" }
const _hoisted_5 = { class: "total pl-2" }

export function render(_ctx, _cache) {
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_datatable = _resolveComponent("v-datatable")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isShowFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile')
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isAdmin)
          ? (_openBlock(), _createBlock(_component_v_select, {
              key: 0,
              class: "small_filter_input mr-4 mt-2",
              modelValue: _ctx.filterNetwork,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = $event => ((_ctx.filterNetwork) = $event)),
                _ctx.applyFilter
              ],
              label: 'user.network',
              items: _ctx.networks,
              clearable: ""
            }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_select, {
          class: "small_filter_input mt-2",
          modelValue: _ctx.filterClub,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = $event => ((_ctx.filterClub) = $event)),
            _ctx.applyFilter
          ],
          label: 'user.club',
          items: _ctx.clubs,
          clearable: ""
        }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("p", {
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clearAllFilters && _ctx.clearAllFilters(...args))),
          class: "clear_all_filters pl-2"
        }, _toDisplayString(_ctx.$t('user.clear_all_filters')), 1)
      ])
    ], 2),
    _createElementVNode("button", {
      class: "filter_button",
      onClick: _cache[3] || (_cache[3] = $event => (_ctx.isShowFilters = !_ctx.isShowFilters))
    }, [
      _createTextVNode(_toDisplayString(!_ctx.isShowFilters ? _ctx.$t('user.filter') : _ctx.$t('user.hide')) + " ", 1),
      _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
        [_vShow, !_ctx.isShowFilters]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("p", _hoisted_5, null, 512), [
        [_vShow, _ctx.isLoad]
      ]),
      _withDirectives(_createElementVNode("p", { class: "total pl-2" }, _toDisplayString(_ctx.$t('user.total')) + ": " + _toDisplayString(_ctx.tableData.length), 513), [
        [_vShow, !_ctx.isLoad]
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isShowFilters ? _ctx.isAdmin ? 'mt-2 table_wrapper_with_open_filter_with_network' : 'mt-2 table_wrapper_with_open_filter' : 'mt-2 table_wrapper')
    }, [
      _createVNode(_component_v_datatable, {
        class: "pointer",
        columTemplates: _ctx.columTemplates,
        data: _ctx.tableData,
        isLoading: _ctx.isLoad,
        onClickOnRow: _ctx.openUser
      }, null, 8, ["columTemplates", "data", "isLoading", "onClickOnRow"])
    ], 2)
  ]))
}